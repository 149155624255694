@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

* {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-size: 16px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}