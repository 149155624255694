.contact-header {
    margin-bottom: 2em;
}

.contact-me {
    padding: 5px 0;
    font-size: 3em;
    margin-bottom: 20px;
    color: #0085D1;
}

.contact-text {
    width: 70%;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

#submit-btn {
    width: 17%;
    margin-top: 10px;
    padding: 10px 0;
    border-radius: 5px;
    cursor: pointer;
}

.social-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    margin-top: 3em;
}